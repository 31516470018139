<template>
  <div class="v-navigation-bar">
    <ul>
      <li v-if="!isPreparation" class="navigationTile">
        <router-link class="routerLink" :to="{ path: '/preparation' }">
          <i
            class="preparationicon next"
            v-if="!isPreparation"
            @click="stepperOnClickHandler()"
          >
          </i>
          <br />
          <p>Preparation</p>
          <CompletionIconNavigationBar
            :JobCode="JobCode"
            CalcType="Preparation"
          />
        </router-link>
      </li>

      <li v-if="isPreparation" class="navigationTile">
        <router-link class="routerLink" :to="{ path: '/preparation' }">
          <i
            class="preparationicon"
            v-if="isPreparation"
            @click="stepperOnClickHandler()"
          >
          </i>
          <br />
          <p>Preparation</p>
          <CompletionIconNavigationBar
            :JobCode="JobCode"
            CalcType="Preparation"
          />
        </router-link>
      </li>

      <li class="separator"></li>

      <li v-if="!isPreWork" class="navigationTile">
        <router-link class="routerLink" :to="{ path: '/researchprework' }">
          <i
            class="preworkicon next"
            v-if="!isPreWork"
            @click="stepperOnClickHandler()"
          >
          </i>
          <br />
          <p>Pre-Work</p>
          <CompletionIconNavigationBar :JobCode="JobCode" CalcType="Prework" />
        </router-link>
      </li>

      <li v-if="isPreWork" class="navigationTile">
        <router-link class="routerLink" :to="{ path: '/researchprework' }">
          <i
            class="preworkicon"
            v-if="isPreWork"
            @click="stepperOnClickHandler()"
          >
          </i>
          <br />
          <p>Pre-Work</p>
          <CompletionIconNavigationBar :JobCode="JobCode" CalcType="Prework" />
        </router-link>
      </li>

      <li class="separator"></li>

      <li v-if="!isWorkshop" class="navigationTile">
        <router-link class="routerLink" :to="{ path: '/workshop' }">
          <i
            class="workshop next"
            v-if="!isWorkshop"
            @click="stepperOnClickHandler()"
          >
          </i>
          <br />
          <p>Workshop</p>
          <CompletionIconNavigationBar :JobCode="JobCode" CalcType="Workshop" />
        </router-link>
      </li>

      <li v-if="isWorkshop" class="navigationTile">
        <router-link class="routerLink" :to="{ path: '/workshop' }">
          <i
            class="workshop"
            v-if="isWorkshop"
            @click="stepperOnClickHandler()"
          >
          </i>
          <br />
          <p>Workshop</p>
          <CompletionIconNavigationBar :JobCode="JobCode" CalcType="Workshop" />
        </router-link>
      </li>

      <li class="separator"></li>

      <li v-if="!isActions" class="navigationTile">
        <router-link class="routerLink" :to="{ path: '/actions' }">
          <i
            class="actions next"
            v-if="!isActions"
            @click="stepperOnClickHandler()"
          >
          </i>
          <br />
          <p>Actions</p>
          <CompletionIconNavigationBar :JobCode="JobCode" CalcType="Actions" />
        </router-link>
      </li>

      <li v-if="isActions" class="navigationTile">
        <router-link class="routerLink" :to="{ path: '/actions' }">
          <i class="actions" v-if="isActions" @click="stepperOnClickHandler()">
          </i>
          <br />
          <p>Actions</p>
          <CompletionIconNavigationBar :JobCode="JobCode" CalcType="Actions" />
        </router-link>
      </li>

      <li class="separator"></li>

      <li v-if="!isReport" class="navigationTile">
        <router-link class="routerLink" :to="{ path: '/report' }">
          <i
            class="report next"
            v-if="!isReport"
            @click="stepperOnClickHandler()"
          >
          </i>
          <br />
          Report
        </router-link>
      </li>

      <li v-if="isReport" class="navigationTile">
        <router-link class="routerLink" :to="{ path: '/report' }">
          <i class="report" v-if="isReport" @click="stepperOnClickHandler()">
          </i>
          <br />
          Report
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import mixin from "../mixins/mixin.js";
import CompletionIconNavigationBar from "./CompletionIconNavigationBar.vue";

export default {
  mixins: [mixin],
  props: ["JobCode"],
  components: { CompletionIconNavigationBar },
  data: () => ({
    drawer: true,
    loading: false,

    preparationComplete: false,
    workshopComplete: false,
    preWorkComplete: false,
    actionsComplete: false,
  }),
  async mounted() {},

  computed: {
    isHome() {
      return this.$route.name === "Home";
    },
    isPreparation() {
      return this.$route.name === "Preparation";
    },
    isPreWork() {
      return this.$route.name === "ResearchPreWork";
    },
    isWorkshop() {
      return this.$route.name === "Workshop";
    },
    isActions() {
      return this.$route.name === "Actions";
    },
    isReport() {
      return this.$route.name === "Report";
    },
  },
  methods: {
    stepperOnClickHandler() {
      this.$session.set("planData", this.$store.state.plan);
    },
  },
};
</script>

<style src="../styles/navigationbar.scss" lang="scss"></style>
