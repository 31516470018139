<template>
  <div>
    <i class="checkmark" v-if="completeComputed"> </i>
  </div>
</template>

<script>
export default {
  props: ["JobCode", "CalcType"],
  components: {},
  data: () => ({
    complete: 0,
  }),

  async mounted() {
    if (this.JobCode && this.CalcType) {
      try {
        await this.$dip
          .get(
            `${this.$store.state.ApiUri}/GetPlanScoreCalculationFunction?JobCode=${this.JobCode}&CalculationType=${this.CalcType}`
          )
          .then((response) => {
            if (this.CalcType == "Preparation") {
              this.complete = response.data.PreparationScore;
            }
            if (this.CalcType == "Actions") {
              this.complete = response.data.ActionsScore;
            }
            if (this.CalcType == "Workshop") {
              this.complete = response.data.WorkshopScore;
            }
            if (this.CalcType == "Prework") {
              this.complete = response.data.PreworkScore;
            }
            // do somethign like if this response contains Progress score, set it to true
          });
      } catch (error) {
        console.log(error);
      }
    }
  },

  computed: {
    completeComputed() {
      if (this.complete > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
